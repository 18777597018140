exports.components = {
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-kontakt-js": () => import("./../../../src/pages/de/kontakt.js" /* webpackChunkName: "component---src-pages-de-kontakt-js" */),
  "component---src-pages-de-presliste-js": () => import("./../../../src/pages/de/presliste.js" /* webpackChunkName: "component---src-pages-de-presliste-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-pricelist-js": () => import("./../../../src/pages/en/pricelist.js" /* webpackChunkName: "component---src-pages-en-pricelist-js" */),
  "component---src-pages-hr-contact-js": () => import("./../../../src/pages/hr/contact.js" /* webpackChunkName: "component---src-pages-hr-contact-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-pricelist-js": () => import("./../../../src/pages/hr/pricelist.js" /* webpackChunkName: "component---src-pages-hr-pricelist-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-pl-contact-js": () => import("./../../../src/pages/pl/contact.js" /* webpackChunkName: "component---src-pages-pl-contact-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-pricelist-js": () => import("./../../../src/pages/pl/pricelist.js" /* webpackChunkName: "component---src-pages-pl-pricelist-js" */)
}

